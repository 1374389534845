import { ReactNode } from "react";

type FrameProps = {
  children: ReactNode;
  className?: string;
};
export const Frame: React.FC<FrameProps> = ({ children, className }) => (
  <div
    className={`rounded-lg border p-4 bg-white dark:bg-slate-800 dark:border-slate-600 ${className}`}
  >
    {children}
  </div>
);
