import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useProduct } from "@features/products/state/use-product";
import { StockTab } from "./stockTab";
import { PeriodPicking, ProductGraph } from "./product-graph";
import { PageLoader } from "@atoms/layout/page-loader";
import { Frame } from "@atoms/layout/frame";
import { ProductType } from "@features/products/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

export const GraphTab = (props: {
  product: ProductType;
  graphContainerClassname?: string;
  tabContainerClassname?: string;
  timePeriod?: PeriodPicking;
  canal: string;
  onChangeGraphSelection?: (
    timePeriod: PeriodPicking,
    canalVente: string
  ) => void;
  type: "bar" | "line";
}) => {
  const { loading } = useProduct(
    props.product.id,
    undefined,
    props.product.tooltip.modeGest
  );

  if (loading)
    return (
      <div className="w-full flex justify-center">
        <PageLoader />
      </div>
    );

  return (
    <div className="w-full h-full items-center gap-8">
      {props.product && (
        <div className="w-full flex flex flex-row justify-center gap-4">
          <Frame className={"grow " + props.graphContainerClassname}>
            <ProductGraph
              className="min-h-40 overflow-x-auto"
              ean={props.product.id}
              product={props.product}
              canal={props.canal}
              timePeriod={props.timePeriod}
              onChangeGraphSelection={props.onChangeGraphSelection}
            />
          </Frame>
          <Frame className={"grow " + props.tabContainerClassname}>
            <StockTab product={props.product} />
          </Frame>
        </div>
      )}
    </div>
  );
};
