import { PageLoader } from "@atoms/layout/page-loader";
import { Tooltip } from "@atoms/tooltip";
import { useActions } from "@features/actions";
import { useAuth } from "@features/general/auth/state/use-auth";
import { ROUTES } from "@features/routes";
import {
  usePersistedAtoms,
  usePersistedState,
} from "@features/utils/hooks/use-local-storage";
import { useListenForShortcuts } from "@features/utils/shortcuts";
import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { BackOfficeRoutes } from "./client";
import { LoginRoutes, useAutoNavigateToLogin } from "./signin";
import { useAutoFocus } from "@features/utils/auto-focus";
import { useTranslation } from "react-i18next";
import {
  getLastRequestTime,
  subscribeToLastRequestTime,
} from "@features/utils/utils-global-state";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useSetRecoilState } from "recoil";
import { InactivModalAtom } from "./client/account/inactiv-modal";
import Env from "@config/environment";
import { AuthJWT } from "@features/general/auth/jwt";
import { differenceInMinutes } from "@features/utils/divers";
import { getLocalStorage } from "@features/utils/local-storage";
import VersioningPage from "./versioning-page";

export default function InitialRouter() {
  useAutoNavigateToLogin();
  const { pathname } = useLocation();
  const { loading, logout, refreshToken, user } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const setInactivModal = useSetRecoilState(InactivModalAtom);
  const [inactivTimer, setInactivTimer] = usePersistedState<number>(
    "session",
    "inactiv-timer",
    0
  );

  const [lastRequestTime, setLastRequestTime] = usePersistedState<Date>(
    "session",
    "last-request-time",
    getLastRequestTime()
  );

  useListenForShortcuts();
  usePersistedAtoms();
  useActions();
  useAutoFocus();

  useControlledEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log(user);
        if (
          AuthJWT.tokenExpiryTime &&
          new Date(AuthJWT.tokenExpiryTime) < new Date()
        ) {
          setInactivModal(false);
          logout();
        } else {
          const lastRefresh = getLocalStorage("user", "last-refresh-token");
          if (
            Env.lastRefresh !== 0 &&
            differenceInMinutes(Date.now(), lastRefresh) >= 20
          ) {
            setInactivModal(false);
            refreshToken();
          }
        }
      }
    });
  }, []);

  useControlledEffect(() => {
    const unsubscribe = subscribeToLastRequestTime((time) => {
      setLastRequestTime(new Date(time));
    });
    return unsubscribe;
  }, []);

  useControlledEffect(() => {
    const handleLastRequestTimeUpdate = (event: CustomEvent<number>) => {
      setLastRequestTime(new Date(event.detail));
    };

    window.addEventListener(
      "lastRequestTimeUpdated",
      handleLastRequestTimeUpdate as EventListener
    );

    return () => {
      window.removeEventListener(
        "lastRequestTimeUpdated",
        handleLastRequestTimeUpdate as EventListener
      );
    };
  }, []);

  useControlledEffect(() => {
    setInactivTimer(0);
  }, [lastRequestTime]);

  useControlledEffect(() => {
    const intervalId = setInterval(() => {
      setInactivTimer((prevInactivTimer) => {
        return prevInactivTimer + 1;
      });
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (inactivTimer >= Env.maxInactiv) {
      setInactivModal(true);
    }
  }, [inactivTimer, setInactivModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const pageName = location.pathname.split("/").pop();
    if (
      !/^\d+$/.test(pageName || "") &&
      pageName !== "true" &&
      pageName !== "false"
    ) {
      document.title =
        "Inférence - " +
        t("onglet." + pageName?.charAt(0) + pageName?.slice(1));
    }
  }, [location, t]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full w-full dark:bg-slate-900 bg-white">
        <PageLoader />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full w-full dark:bg-slate-900 bg-white">
          <PageLoader />
        </div>
      }
    >
      <div className="flex min-h-full dark:bg-slate-900 bg-white h-full">
        <Routes>
          <Route path="/">
            {BackOfficeRoutes()}
            {LoginRoutes()}
            <Route path="*" element={<Navigate to={ROUTES.Home} />} />
            <Route path="" element={<Navigate to={ROUTES.Home} />} />
            <Route path="/versioning" element={<VersioningPage />} />
          </Route>
        </Routes>
        <Toaster position="top-center" />
        <Tooltip />
      </div>
    </Suspense>
  );
}
