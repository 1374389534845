import { Checkbox } from "@atoms/input/input-checkbox";
import { BaseBoldSmall } from "@atoms/text";
import {
  ArrowSmRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ReactNode } from "react";

export type CardProps<T> = {
  className?: string;
  title?: string;
  disabled?: boolean;
  children: ReactNode;
  checkable?: boolean;
  item?: T;
  onInfoClick: (item: T) => void;
};

export const GenericCard = <T,>({ ...props }: CardProps<T>) => {
  return (
    <div
      className={`relative hover:cursor-pointer border-2 rounded-md ${
        props.disabled ? "" : ""
      }`}
    >
      {props.disabled && (
        <div className="absolute top-0 left-0 h-full w-full rounded-lg bg-gray-200 bg-opacity-40" />
      )}
      <div
        className={`group flex flex-col min-h-26 max-h-26 rounded-lg relative ${
          props.disabled ? "opacity-30" : "bg-gray-200"
        }`}
        onClick={() => {}}
      >
        <div
          className={
            "flex flex-row h-8 justify-start rounded-lg " + props.className
          }
        >
          {props.checkable && (
            <div className="flex flex-col grow justify-center items rounded-lg">
              <Checkbox className="border-slate-500 mx-2 rounded-lg" />
            </div>
          )}
          <div
            className={`flex flex-col grow justify-center rounded-lg ${
              props.checkable ? "" : "mx-4"
            }`}
          >
            <BaseBoldSmall
              className={
                !props.disabled
                  ? "group-hover:underline hover:cursor-pointer"
                  : ""
              }
            >
              {props.title}
            </BaseBoldSmall>
          </div>
        </div>
        <div className="flex flex-col grow w-full mx-4 my-2 rounded-lg">
          {props.children}
        </div>
        {!props.disabled && (
          <div
            className="absolute bg-primary p-1 rounded-md bottom-0 right-0 transition duration-200 flex items-center justify-center 
           group-hover:text-blue-600 bg-blue-100 hover:cursor-pointer text-slate-600 bg-gray-300"
          >
            <ArrowSmRightIcon className="w-5 h-5 group-hover:w-6 group-hover:h-6" />
          </div>
        )}
      </div>
      <div
        id="info"
        className="absolute bg-primary p-1 rounded-md top-0 right-0 transition duration-200 flex items-center justify-center text-slate-600 bg-gray-300"
        onClick={(e) => {
          e.stopPropagation();
          if (props.item) props.onInfoClick(props.item);
        }}
      >
        <InformationCircleIcon className="w-5 h-5 hover:w-6 hover:h-6" />
      </div>
    </div>
  );
};
