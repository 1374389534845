import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseSmall, Info, InfoSmall } from "@atoms/text";
import { CheckCircleIcon, TagIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { atom, useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {
  ReceptionLine,
  ReceptionUnit,
  RelatedOrder,
} from "@features/reception/types";
import { useReception } from "@features/reception/state/use-reception";

type PrintCdeCliModalType = {
  open: boolean;
  receipt: ReceptionUnit | null;
  item: ReceptionLine | null;
  orders: RelatedOrder[];
};
export const PrintLabelsCdeCliModalAtom = atom<PrintCdeCliModalType>({
  key: "PrintCdeCliModal",
  default: {
    open: false,
    receipt: {} as ReceptionUnit | null,
    item: {} as ReceptionLine | null,
    orders: [],
  },
});

export const PrintLabelsCdeCliModal = () => {
  const [printCdeCliModal, setPrintCdeCliModal] = useRecoilState(
    PrintLabelsCdeCliModalAtom
  );
  const { loading, selectedReception, printLabels } = useReception();

  const { t } = useTranslation();

  const handleClose = () =>
    setPrintCdeCliModal({
      ...printCdeCliModal,
      open: false,
      receipt: null,
      item: null,
      orders: [],
    });

  return (
    <Modal
      className="sm:max-w-[900px]"
      loading={loading}
      open={printCdeCliModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Impression d'étiquettes"}>
        <div>
          <Info>{`${printCdeCliModal.item?.desig}`} </Info>
          <Info>{`${printCdeCliModal.item?.ean13}`} </Info>
        </div>
        <div className="grow flex flex-col gap-2">
          <Table
            className="mt-2"
            emptyTabText="Aucune commande client associée à cet article"
            columns={[
              {
                title: t("reception.infosCommandes"),
                render: (order) => (
                  <div className="flex flex-col">
                    {order.numCdeCli === "" ? (
                      <Base>STOCK</Base>
                    ) : (
                      <>
                        <BaseSmall>Num cde: {order.numCdeCli}</BaseSmall>
                        <BaseSmall>
                          {order.qteCde} cdé cli {order.codeCanalVente}
                        </BaseSmall>
                      </>
                    )}
                  </div>
                ),
              },
              {
                title: t("reception.Client"),
                orderable: true,
                render: (order) => (
                  <div className="flex flex-col gap-2">
                    {order.numCdeCli === "" ? (
                      <Base>STOCK</Base>
                    ) : (
                      <>
                        <Base>{order.nomClient}</Base>
                        <InfoSmall>
                          {order.mailCli} {order.telCli}
                        </InfoSmall>
                      </>
                    )}
                  </div>
                ),
              },

              {
                title: "Étiqueter",
                render: (order, i) => (
                  <div className="flex gap-2">
                    <div style={{ maxWidth: 96, minWidth: 96 }}>
                      <InputCounter
                        disabled={selectedReception.trait}
                        size="sm"
                        value={order.nbrEtiq}
                        min={0}
                        onChange={(newValue) => {
                          let lineIndex = -1;
                          let newOrders: RelatedOrder[] = [];
                          setPrintCdeCliModal(
                            (previous: PrintCdeCliModalType) => {
                              lineIndex = previous.orders.findIndex(
                                (l) => l.numCdeCli === order.numCdeCli
                              );
                              if (lineIndex !== -1) {
                                newOrders = [...previous.orders];
                                const tempOrder = { ...newOrders[lineIndex] };
                                tempOrder.nbrEtiq = newValue;
                                newOrders[lineIndex] = tempOrder;

                                return {
                                  ...previous,
                                  orders: [...newOrders],
                                };
                              } else {
                                return previous;
                              }
                            }
                          );
                        }}
                      />
                    </div>
                    <Button
                      className="shrink-0"
                      size="sm"
                      theme="secondary"
                      disabled={loading || order.linePk === ""}
                      onClick={() => {
                        if (printCdeCliModal.item)
                          printLabels(
                            undefined,
                            printCdeCliModal.item,
                            order.nbrEtiq,
                            order.linePk.split("|")[0]
                          );
                      }}
                      icon={({ className }) => (
                        <TagIcon className={className} />
                      )}
                    />
                  </div>
                ),
              },
            ]}
            data={printCdeCliModal.orders}
          />
          <div className="w-full flex justify-center gap-8 my-4">
            <Button
              shortcut={["enter"]}
              theme="primary"
              icon={(p) => <CheckCircleIcon {...p} />}
              onClick={async () => {
                printCdeCliModal.orders.forEach(async (el) => {
                  if (printCdeCliModal.item && el.nbrEtiq)
                    await printLabels(
                      undefined,
                      printCdeCliModal.item,
                      el.nbrEtiq,
                      el.linePk.split("|")[0]
                    );
                });
                handleClose();
              }}
            >
              Confirmer les impressions
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
