import { BaseBold, Info } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { formatTime } from "@features/utils/format/dates";
import { formatAmount } from "@features/utils/format/strings";

export const GenericBasicTable = (props: {
  columns: {
    columnName: string;
    columnKey: string;
    format?: string;
    total?: boolean;
  }[];
  objectList: any[];
  canal?: string;
}) => {
  const { current } = useShopLocations();
  return (
    <table className="w-full border-collapse bg-white shadow-sm rounded-lg overflow-hidden">
      <thead>
        <tr>
          {props.columns.some((c) => c.total) && <th> </th>}
          {props.columns.map((c) => (
            <th className="p-2 rounded-lg">
              <BaseBold className="flex justify-center">
                {c.columnName}
              </BaseBold>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.objectList.map((p, index) => (
          <tr key={index} className="even:bg-gray-100">
            {props.columns.some((c) => c.total) && (
              <th className="!bg-white"> </th>
            )}
            {props.columns.map((c) => {
              if (!p[c.columnKey] && p[c.columnKey] !== 0)
                return (
                  <td className="p-2 text-center">
                    <Info className="text-center">--</Info>
                  </td>
                );
              return (
                <td className="p-2 text-left">
                  {!c.format && (
                    <Info className="flex justify-center">
                      {p[c.columnKey]}{" "}
                    </Info>
                  )}
                  {c.format === "number" && (
                    <Info className="flex justify-end">{p[c.columnKey]}</Info>
                  )}
                  {c.format === "date" && (
                    <Info className="flex justify-center">
                      {formatTime(p[c.columnKey] as string, "fr-FR", {
                        keepTime:
                          new Date(p[c.columnKey] as string).getSeconds() === 0
                            ? false
                            : true,
                        keepSeconds: false,
                        keepDate: true,
                      })}
                    </Info>
                  )}
                  {c.format === "price" && (
                    <Info className="flex justify-end">
                      {formatAmount(
                        parseFloat(p[c.columnKey] as string),
                        current?.devisSymb || "€",
                        current?.devisNbDecim || 0
                      )}
                    </Info>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
        {props.columns.some((c) => c.total) && (
          <tr className="bg-gray-200 font-semibold">
            {props.columns.some((c) => c.total) && (
              <td className="p-2 text-center">Total</td>
            )}
            {props.columns.map((c) =>
              c.total ? (
                <td className="flex justify-end p-2 text-left">
                  {props.objectList.reduce(
                    (sum, item) => sum + item[c.columnKey],
                    0
                  )}
                </td>
              ) : (
                <td className="p-2 text-left"></td>
              )
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
};
