import { Shortcut, useShortcuts } from "@features/utils/shortcuts";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import _ from "lodash";
import React, { useState } from "react";

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement> &
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    "size"
  > {
  highlight?: boolean;
  theme?: "plain";
  label?: string;
  size?: "sm" | "md" | "lg";
  feedback?: string;
  hasError?: boolean;
  autoSelect?: boolean;
  multiline?: boolean;
  inputComponent?: React.ReactNode;
  inputClassName?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  shortcut?: Shortcut[];
}

export const defaultInputClassName = (theme: "plain" = "plain") => {
  return "rounded-lg shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full text-sm border-gray-200 dark:bg-slate-900 dark:border-slate-700 dark:text-white dark:focus:ring-sky-900 dark:focus:border-sky-900";
};

export const errorInputClassName = (theme: "plain" = "plain") => {
  return (
    defaultInputClassName(theme) +
    " bg-red-50 border-red-300 dark:bg-red-900 dark:border-red-800"
  );
};

export const Input = ({
  highlight,
  theme,
  label,
  size,
  feedback,
  hasError,
  autoSelect = true, // valeur par défaut
  multiline,
  inputComponent,
  inputClassName,
  className,
  inputRef: propInputRef,
  shortcut,
  ...rest
}: InputProps) => {
  let combinedInputClassName = hasError
    ? errorInputClassName(theme)
    : defaultInputClassName(theme);
  combinedInputClassName += rest.disabled ? " opacity-25" : "";

  const internalRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const inputRef = propInputRef || internalRef;

  if (highlight && rest.value)
    combinedInputClassName += " !ring-2 !ring-yellow-500 dark:!ring-yellow-900";

  if (!multiline) {
    if (size === "lg") combinedInputClassName += " h-11";
    else if (size === "sm") combinedInputClassName += " h-7";
    else combinedInputClassName += " h-9";
  }

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputClick = (event: any) => {
    // Appelez d'abord la fonction onClick passée en props, le cas échéant
    if (rest.onClick) {
      rest.onClick(event);
    }
    // Puis exécutez la logique spécifique après le clic
    if (autoSelect) (event.target as HTMLInputElement).select();
  };

  useShortcuts(!rest.disabled && shortcut?.length ? [...shortcut] : [], (e) => {
    (inputRef as any)?.current?.focus();
  });

  return (
    <>
      {inputComponent ||
        (multiline ? (
          <textarea
            ref={inputRef as React.Ref<HTMLTextAreaElement>}
            className={
              combinedInputClassName + " " + inputClassName + " " + className
            }
            {..._.omit(
              rest as any,
              "label",
              "inputRef",
              "inputClassName",
              "className",
              "value",
              "size",
              "multiline",
              "highlight"
            )}
            value={rest.value}
            onClick={handleInputClick}
          />
        ) : rest.type === "password" ? (
          <div className="relative">
            <input
              type={passwordVisible ? "text" : "password"}
              className={
                combinedInputClassName + " " + inputClassName + " " + className
              }
              {..._.omit(
                rest,
                "label",
                "inputRef",
                "inputClassName",
                "className",
                "size",
                "highlight",
                "type"
              )}
              onClick={handleInputClick}
            />
            {passwordVisible ? (
              <EyeOffIcon
                className="h-5 w-5 text-slate-500 opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  togglePasswordVisibility();
                }}
              />
            ) : (
              <EyeIcon
                className="h-5 w-5 text-slate-500 opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  togglePasswordVisibility();
                }}
              />
            )}
          </div>
        ) : (
          <input
            ref={inputRef as React.Ref<HTMLInputElement>}
            type="text"
            className={
              combinedInputClassName + " " + inputClassName + " " + className
            }
            {..._.omit(
              rest,
              "label",
              "inputRef",
              "inputClassName",
              "className",
              "size",
              "highlight"
            )}
            onClick={handleInputClick}
          />
        ))}
    </>
  );
};
