import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base } from "@atoms/text";
import { SupplierOrderPrepHeader } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Checkbox } from "@atoms/input/input-checkbox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { useShopLocations } from "@features/general/auth/state/use-store-location";

export const SupplierSendOrderPage = () => {
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  const { current } = useShopLocations();
  const navigate = useNavigate();
  const [orderHeaders, setOrderHeaders] = useState<SupplierOrderPrepHeader[]>(
    []
  );
  const { getOrderPreparationHeaders, changeSelectedPrepHeader } =
    useSuppliers();
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );

  const orderColumns: Column<SupplierOrderPrepHeader>[] = [
    {
      title: "Fournisseur",
      id: "codeFourn",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Raison-sociale",
      id: "raisSocial",
      render: (p) => <Base>{p.raisSocial}</Base>,
    },
    {
      title: "Panier",
      id: "panie",
      render: (p) => <Base>{p.panie}</Base>,
    },
    {
      title: "Mode exp.",
      id: "codeModeExpe",
      render: (p) => <Base>{p.codeModeExpe}</Base>,
    },
    {
      title: "Qte",
      id: "qte",
      render: (p) => <Base>{p.qte}</Base>,
    },
    {
      title: "Brut",
      id: "JSP",
      render: (p) => <Base>JSP</Base>,
    },
    {
      title: "Franco",
      id: "codeFourn",
      render: (p) => (
        <div className="w-full flex gap-2">
          <Base>{p.francPort}</Base>
          <Checkbox value={p.francoAtteint} />
        </div>
      ),
    },
    {
      title: "Référ",
      id: "ref",
      render: (p) => <Base>{p.ref}</Base>,
    },
    {
      title: "Ligne",
      id: "nbrLigne",
      render: (p) => <Base>{p.nbrLigne}</Base>,
    },
    {
      title: "En envoi",
      id: "enCours",
      render: (p) => <Base>{p.enCours}</Base>,
    },
    {
      title: "Qte cli",
      id: "qteCli",
      render: (p) => <Base>{p.qteCli}</Base>,
    },
    {
      title: "Poids",
      id: "totPoids",
      render: (p) => <Base>{p.totPoids}</Base>,
    },
  ];

  return (
    <Page>
      <>
        <Button onClick={() => setSendOrderModal({ open: true })}>
          Envoi commande
        </Button>
        <TableGridSwitch
          data={orderHeaders}
          onChangeMode={(v) => setGridMode({ activated: v })}
          gridMode={gridMode.activated}
          tableColumns={orderColumns}
          total={0}
          onClick={(e) => {
            changeSelectedPrepHeader(e);
            navigate(ROUTES.SupplierSendOrderLinePage);
          }}
          onRequestData={async () => {
            const res = await getOrderPreparationHeaders(
              "LIVR",
              current?.codeLieu
            );
            if (res) setOrderHeaders(res);
            else setOrderHeaders([]);
          }}
          renderGrid={() => <> </>}
        />
      </>
    </Page>
  );
};
