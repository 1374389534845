import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, Info } from "@atoms/text";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { atom, useRecoilState } from "recoil";
import { ReceptionLine, ReceptionUnit } from "@features/reception/types";
import { useReception } from "@features/reception/state/use-reception";
import { Column } from "@molecules/table/table";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import { BookRender } from "@atoms/book";
import { useState } from "react";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

type EclatKitModalType = {
  open: boolean;
  receipt: ReceptionUnit | null;
  products: ReceptionLine[];
  closeOnValid?: boolean;
};
export const EclatKitModalAtom = atom<EclatKitModalType>({
  key: "EclatKitModal",
  default: {
    open: false,
    receipt: {} as ReceptionUnit | null,
    products: [],
    closeOnValid: false,
  },
});

export const EclatKitModal = () => {
  const [eclatKitModal, setEclatKitModal] = useRecoilState(EclatKitModalAtom);
  const { loading, selectedReception, eclatKit, getLines, setReceiptLines } =
    useReception();
  const [selectedProducts, setSelectedProducts] = useState<ReceptionLine[]>([]);

  const handleClose = () =>
    setEclatKitModal({
      ...eclatKitModal,
      open: false,
      receipt: null,
      products: [],
      closeOnValid: false,
    });

  useControlledEffect(() => {
    setSelectedProducts(eclatKitModal.products);
  }, [eclatKitModal.products]);

  const tableColumns: Column<ReceptionLine>[] = [
    {
      title: "Articles",
      id: "article",
      render: (p) => (
        <div
          className="flex flex-row grow xl:max-w-screen-2xl max-w-44 items-center space-x-4"
          onClick={() => {}}
        >
          {selectedReception && (
            <div
              key={`${selectedReception.numRecep}`}
              className="w-10 print:hidden"
            >
              <BookRender src={p.imageURL} productType={p.tooltip?.modeGest} />
            </div>
          )}
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal w-full cursor-text"
              data-html={true}
              data-tooltip={
                p.tooltip ? getCommonProductTooltip(p, p.tooltip) : p.desig
              }
            >
              {p.desig}
            </Base>
            <Info
              className="cursor-text"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onDoubleClick={(e) => {
                e.stopPropagation();
                const range = document.createRange();
                range.selectNode(e.currentTarget);
              }}
            >
              {p.ean13}
            </Info>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Modal
      className="sm:max-w-[900px]"
      loading={loading}
      open={eclatKitModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Éclatement des kits"}>
        <div className="grow flex flex-col gap-4">
          <BaseBold>
            Des kits sont présents dans cette réceptions. Sélectionnez les kits
            que vous souhaitez éclater.
          </BaseBold>
          <Table
            className="mt-2"
            emptyTabText="Aucune commande client associée à cet article"
            columns={tableColumns}
            data={eclatKitModal.products}
            selectAllDefault={true}
            onSelect={(newSelectedProducts) =>
              setSelectedProducts(newSelectedProducts)
            }
            rowIndex="ean13"
          />
          <div className="w-full flex justify-center gap-8 my-4">
            <Button
              shortcut={["enter"]}
              theme="primary"
              icon={(p) => <CheckCircleIcon {...p} />}
              onClick={async () => {
                const closeEvent = new CustomEvent("toCloseReceipt");
                if (eclatKitModal.receipt && selectedProducts.length > 0) {
                  const res = await eclatKit(
                    eclatKitModal.receipt,
                    selectedProducts
                  );
                  if (res) {
                    if (eclatKitModal.closeOnValid)
                      window.dispatchEvent(closeEvent);
                    else {
                      getLines(selectedReception, {
                        orderBy: "dateCreat",
                        orderDir: "DESC",
                        pageNumber: 0,
                        pageSize: 20,
                      }).then((res) =>
                        setReceiptLines({
                          pagination: {
                            pageNumber: res.pageNumber,
                            pageSize: res.pageSize,
                            pageCount: res.pageCount,
                            totalRows: res.totalRows,
                            rowsReturned: res.rowsReturned,
                          },
                          items: res.items,
                        })
                      );
                    }
                    handleClose();
                  }
                } else {
                  eclatKitModal.closeOnValid &&
                    window.dispatchEvent(closeEvent);
                  handleClose();
                }
              }}
            >
              {eclatKitModal.closeOnValid
                ? "Valider et clôturer"
                : "Valider l'éclatement"}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
