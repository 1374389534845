import Avatar from "@atoms/avatar/avatar";
import Link from "@atoms/link";
import { BaseSmall } from "@atoms/text";
import { useAuth } from "@features/general/auth/state/use-auth";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useSetRecoilState } from "recoil";
import { AccountModalAtom } from "../client/account/modal";

export const Account = () => {
  const { user } = useAuth();
  const setAccountModal = useSetRecoilState(AccountModalAtom);
  return (
    <div className="relative flex items-center group">
      <Link
        onClick={() => setAccountModal(true)}
        noColor
        className="inline-flex flex-row items-center"
      >
        <Avatar
          size={7}
          fallback={[user?.prenom, user?.nom].map((a) => a).join(" ")}
          className="h-7 w-7 mr-1 md:mr-2 shrink-0 group-hover:h-8 group-hover:w-8"
        />
        <BaseSmall
          noColor
          className="hidden sm:block mr-1 md:mr-2 group-hover:font-semibold"
          onClick={() => setAccountModal(true)}
        >
          {[user?.prenom, user?.nom].map((a) => a).join(" ")}
        </BaseSmall>
        <ChevronDownIcon
          className="h-4 w-4 group-hover:text-blue-500 group-hover:h-5 group-hover:w-5"
          onClick={() => setAccountModal(true)}
        />
      </Link>
    </div>
  );
};
