import { AuthJWT } from "@features/general/auth/jwt";
import { CommonSearchResultType, SuggType } from "@features/types";
import { fetchServer } from "@features/utils/fetch-server";
import {
  Book,
  Paper,
  ProductGraphRequest,
  ProductGraphResponse,
  ProductSupplementOrder,
  ProductSupplementReturn,
  ProductType,
  ProductTypeShort,
  ProductsBasketType,
  SearchProductAdvancedRequestType,
  SearchProductsRequestType,
} from "../types";
import _ from "lodash";
import { FetchError } from "@features/utils/errors";
import toast from "react-hot-toast";

export class ProductsApiClient {
  static getProducts = async (search: SearchProductsRequestType) => {
    const data = await fetchServer("/product/search", {
      method: "POST",
      body: JSON.stringify({
        codeLieu: AuthJWT.codeLieu,
        ...search,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as CommonSearchResultType<ProductTypeShort>;
  };

  static getProductScan = async (productID: string) => {
    const data = await fetchServer("/product", {
      method: "POST",
      body: JSON.stringify([{ id: productID }]),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ProductTypeShort;
  };

  static getProduct = async (
    ean: string,
    productType = "",
    specific?: string
  ) => {
    const data = await fetchServer(
      `/${productType}?ID=${ean}${specific ? "&Supplement=" + specific : ""}`,
      {
        method: "GET",
        reloadOn401: false,
      }
    );
    if (data.status === 400) {
      throw new FetchError(data.status.toString(), 400);
    }
    if (data.status === 401) {
      throw new FetchError(data.status.toString(), 401);
    }
    if (data.status === 403) {
      throw new FetchError(data.status.toString(), 403);
    }
    if (data.status === 404) {
      throw new Error("404 - Product not found");
    }
    if (data.status === 503) {
      throw new FetchError(data.status.toString(), 503);
    }
    const result = await data.json();
    if (specific)
      return {
        ...result,
        ...result.supplementReturn,
        ...result.supplementOrder,
      } as ProductSupplementReturn | ProductSupplementOrder;

    return result as Book | Paper;
  };

  static getProductConditionAchat = async (ean: string, productType = "") => {
    const data = await fetchServer(`/${productType}/${ean}/purchaseprices`, {
      method: "POST",
      body: JSON.stringify({}),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getProductGraphVente = async (graphRequest: ProductGraphRequest) => {
    const data = await fetchServer(`/product/graph`, {
      method: "POST",
      body: JSON.stringify({ ...graphRequest }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ProductGraphResponse[];
  };

  static getProductsAdvanced = async (
    search: SearchProductAdvancedRequestType,
    endpointType: string,
    productType: string
  ) => {
    const endpoint =
      productType !== "all"
        ? `/product/search/advanced/${endpointType}`
        : "/product/search/advanced";

    const jsonObj =
      productType !== "all"
        ? { ...search, codeTypeProd: productType }
        : { ...search };

    const data = await fetchServer(endpoint, {
      method: "POST",
      body: JSON.stringify(jsonObj),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title || "Erreur de recherche");
    }

    //TODO: Monkey fix until backend solve the problem
    return result?.items
      ? result
      : ({ items: result } as CommonSearchResultType<ProductTypeShort>);
  };

  //Devra être amélioré quand les autres champs seront dispo en backs
  static getSuggestions = async (query: { [key: string]: string | null }) => {
    const data = await fetchServer(
      `/product/predictsearch?${Object.entries(query)
        .filter(([, v]) => v)
        .map(([key, value]) => `${key}=${encodeURIComponent(value!)}`)
        .join("&")}`,
      {
        method: "GET",
      }
    );

    const result = await data.json();

    if (data.status !== 200) {
      throw new Error(result.title);
    }

    //Monkey fix until backend align results
    let a = result.map((el: SuggType) => ({
      label: `${el.label || el.libelle || el.designation}${
        el.code ? ` (${el.code})` : ""
      }`,
      value: el.code || el.libelle || el.designation || el.label,
      codeRech: el.codeRech,
    })) as { label: string; value: string; codeRech?: string }[];

    const b = _.uniqBy(a, "label");
    if (b.length !== a.length) {
      a = a.map((el) => ({
        label: el.label,
        value: el.value,
        codeRech: el.codeRech,
      }));
    }

    return b;
  };

  // Cette route renvoi un aggregat de plusieurs champs (aka auteur, titre, editeur, etc...)
  static getAggregatedSuggestions = async (query: {
    [key: string]: string | null;
  }) => {
    if (query.CodeTypeProd === "all") {
      delete query["CodeTypeProd"];
    }
    const data = await fetchServer(
      `/product/predictsearch?${Object.entries(query)
        .filter(([, v]) => v)
        .map(([key, value]) => `${key}=${encodeURIComponent(value!)}`)
        .join("&")}`,
      {
        method: "GET",
      }
    );

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }

    const a = [] as { label: string; value: string }[];
    result.forEach((el: any) =>
      ["label", "auteur", "designation", "editeur"]
        .filter((k) => {
          return (el as any)[k];
        })
        .forEach((k) => {
          a.push({
            label: (el as any)[k],
            value: (el as any)[k],
          });
        })
    );

    return _.uniqBy(a, "label");
  };

  static referenceProduct = async (ean: string) => {
    const data = await fetchServer("/product/reference", {
      method: "POST",
      body: JSON.stringify({ id: ean }),
    });
    if (data.status !== 204) {
      throw new Error("Erreur de référencement");
    }
    return true;
  };

  static productLabel = async (
    products: Array<{
      ean13: string;
      codeCanalVente?: string;
      nbrEtiq?: number;
    }>
  ) => {
    const productRequests = products.map(
      ({ ean13, codeCanalVente, nbrEtiq = 1 }) => ({
        ean13,
        codeCanalVente,
        nbrEtiq,
      })
    );

    const data = await fetchServer("/product/labels", {
      method: "POST",
      body: JSON.stringify(productRequests),
    });

    if (data.status !== 204) {
      throw new Error("Erreur lors de l'inmpression des étiquettes");
    }

    toast.success("Impression(s) validée(s)");
  };

  static refreshBasket = async (eanList: { id: string }[]) => {
    const data = await fetchServer("/product", {
      method: "POST",
      body: JSON.stringify(eanList),
    });
    if (data.status !== 200) {
      throw new Error("Erreur rafraichissement");
    }
    const result = await data.json();
    return result as ProductType[] | ProductTypeShort[] | ProductsBasketType[];
  };

  static getLieuStock = async (id: string) => {
    const data = await fetchServer(`/product/${id}/stock`);
    const result = await data.json();
    if (data.status !== 200) {
      toast.error(result.detail);
      throw new Error("");
    }
    return result;
  };

  static updatePrice = async (
    product: Book,
    codeLieu: string,
    prixVenteRef: number
  ) => {
    const data = await fetchServer("/product/price", {
      method: "PATCH",
      body: JSON.stringify({
        ean13: product.id,
        codeCanalVente: product.listPlcv.find((el) => el.princ === true)
          ?.codeCanalVente,
        prixPublic: prixVenteRef,
        codeLieu: codeLieu,
        prixVenteRef: prixVenteRef,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  //Route Temporaire en attendant les endpoits et les spécifités de chaque champ
  static getFieldSuggestion = async (letters: string) => {
    /* const data = await fetchServer("/products/reco", {
      method: "POST",
      body: JSON.stringify({ ean }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ProductType; */

    return [
      {
        label: "HACHETTE",
        value: "HACHETTE",
      },
      {
        label: "KAZE",
        value: "KAZE",
      },
      {
        label: "GALLIMARD",
        value: "GALLIMARD",
      },
    ];
  };
}
