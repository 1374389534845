export class BarcodeUtils {
  static anyToEan13 = (ean: string) => {
    switch (ean.length) {
      case 8:
        return this.convertEan8toEan13(ean);
      default:
        return ean;
    }
  };
  static convertEan8toEan13 = (ean8: string): string => {
    if (ean8.length !== 8) {
      throw new Error("Le code fourni n'est pas un EAN-8 valide.");
    }
    const ean13 = ean8.padStart(13, "0");
    return ean13;
  };

  static onScan = (
    scanHandler: (value: string) => void,
    {
      barcodePrefix,
      endCharacterCode,
      scanDuration,
      event,
    }: {
      barcodePrefix?: string;
      endCharacterCode?: number;
      scanDuration?: number;
      event?: string;
    } = {}
  ) => {
    if (barcodePrefix && typeof barcodePrefix !== "string") {
      throw new TypeError("barcodePrefix must be a string");
    }

    if (endCharacterCode && typeof endCharacterCode !== "number") {
      throw new TypeError("endCharacterCode must be a number");
    }

    if (scanDuration && typeof scanDuration !== "number") {
      throw new TypeError("scanDuration must be a number");
    }

    if (event && typeof event !== "string") {
      throw new TypeError("event must be a string");
    }

    barcodePrefix = barcodePrefix || "";
    endCharacterCode = endCharacterCode || 13;
    scanDuration = scanDuration || 300;
    event = event || "keypress";

    let finishScanTimeoutId: any = null;
    let prefixBuffer = "";
    let valueBuffer = "";
    let valueBufferBis = "";
    let matchedPrefix = false;
    //let lastKeyPressTime = 0;

    const finishScan = function () {
      if (matchedPrefix) {
        scanHandler(valueBuffer);
      }
      resetScanState();
    };

    const resetScanState = function () {
      clearTimeout(finishScanTimeoutId);
      finishScanTimeoutId = null;
      prefixBuffer = "";
      valueBuffer = "";
      valueBufferBis = "";
      matchedPrefix = false;
    };

    const keyPressHandler = function (e: KeyboardEvent) {
      const char = String.fromCharCode(e.which);
      const charIndex = barcodePrefix!.indexOf(char);
      const expectedPrefixSlice = barcodePrefix!.slice(0, charIndex);
      //const currentTime = Date.now();

      if (!finishScanTimeoutId) {
        finishScanTimeoutId = setTimeout(resetScanState, scanDuration);
        //lastKeyPressTime = currentTime;
      }
      if (valueBufferBis.length === 13) {
        if (!document.activeElement?.classList.contains("to-focus")) {
          e.preventDefault();
          //----------------------
          const eventEan = new CustomEvent("eanScanned", {
            detail: { ean: valueBuffer }, // Passer des données supplémentaires avec l'événement
          });
          document.dispatchEvent(eventEan); // Dispatcher l'événement sur l'objet document
          //-----------------------

          //(document.activeElement as HTMLInputElement).value = "SAZJFDSGJFDJK";
        }
      }

      if (e.which === endCharacterCode) {
        clearTimeout(finishScanTimeoutId);
        finishScan();
      } else if (
        prefixBuffer === expectedPrefixSlice &&
        char === barcodePrefix!.charAt(charIndex)
      ) {
        prefixBuffer += char;
      } else if (matchedPrefix || barcodePrefix === "") {
        valueBuffer += char;
        if (/^\d$/.test(e.key)) {
          valueBufferBis += e.key;
        }
      }

      if (prefixBuffer === barcodePrefix) {
        matchedPrefix = true;
      }
    };

    const removeListener = function () {
      document.removeEventListener(event!, keyPressHandler as any);
    };

    document.addEventListener(event!, keyPressHandler as any);
    return removeListener;
  };
}
