import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { useScan } from "@atoms/input-scan/use-scan";
import { InputWithSuggestions } from "@atoms/input/input-with-suggestion";
import { Page } from "@atoms/layout/page";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info, InfoSmall, Subtitle, Title } from "@atoms/text";
import ProductCard, { ProductActions } from "@components/product";
import Env from "@config/environment";
import { ProductTypeOrder } from "@features/customer/type";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import { useProducts } from "@features/products/state/use-products";
import { ProductType, ProductTypeShort } from "@features/products/types";
import { ROUTES } from "@features/routes";
import { ShortcutMapping } from "@features/settings/shortcuts/mapping";
import { focusInput } from "@features/utils/divers";
import { formatAmount } from "@features/utils/format/strings";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import {
  DotsHorizontalIcon,
  PlusIcon,
  ReplyIcon,
  ShoppingBagIcon,
  TrashIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { OptionsAtom } from "@molecules/options-modal";
import { Column } from "@molecules/table/table";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export const BasketPage = () => {
  const { id: basketId } = useParams<{ id: string }>();
  const { getProductScan } = useProducts();
  const {
    baskets: productsBaskets,
    setCurrent,
    getBasket,
    removeBasket,
  } = useBaskets();
  const {
    getPrice,
    basketCustomer,
    products: basket,
    refresh: refreshBasketProducts,
    add,
    getCount,
  } = useBasket(basketId || "");
  const openOptions = useSetRecoilState(OptionsAtom);
  const [share, setShare] = useState(false);
  const [scanValue, setScanValue] = useState("");
  const { scanOn } = useScan();
  const { current: currentShop } = useShopLocations();

  const { t } = useTranslation();
  const navigate = useNavigate();
  //const location = useLocation();
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    `product-basket-${basketId}-grid-mode`,
    {
      activated: true,
    }
  );

  useEffect(() => {
    setCurrent(basketId || "");
  }, [basketId, setCurrent]);

  useEffect(() => {
    document.title = `Inférence - ${t("onglet.panier")} (${getCount()})`;
  }, [getCount, t]);

  useControlledEffect(() => {
    if (basket.length > 0) refreshBasketProducts();
  }, [basketId]);

  const currentBasketProducts =
    productsBaskets.find((e) => e.id === basketId)?.products || [];

  const handleSubmit = async (ean: string) => {
    const query = ean;
    setScanValue("");
    if (query.length > 0) {
      const product = await getProductScan(query);
      if (product) add(product, 1);
      setTimeout(() => {
        if (product) focusInput(`[id="${product.id}"] input`);
      }, 100);
    }
  };

  const columns: Column<{
    product: ProductType | ProductTypeShort | ProductTypeOrder;
    statu?: "ACO" | "RES" | "STO" | undefined;
    aside?: boolean | undefined;
  }>[] = [
    {
      title: "Articles",
      orderable: true,
      render: (p, { responsive }) =>
        responsive ? (
          <div className="text-right" onClick={() => {}}>
            <Base data-tooltip={p.product.designation}>
              {p.product.designation}
            </Base>
            <br />
            <Info>{p.product.id}</Info>
          </div>
        ) : (
          <div
            className="flex flex-row items-center space-x-4 cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-10 print:hidden">
              <BookRender
                src={p.product.imageURL}
                productType={p.product.modeGest}
              />
            </div>

            <div className="flex flex-col">
              <Base
                className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                data-tooltip={""}
              >
                {p.product.designation}
              </Base>
              <Info>{p.product.id}</Info>
            </div>
          </div>
        ),
    },
    {
      title: "Informations",
      orderable: true,
      render: (p) => (
        <div className="grow flex flex-row">
          <div className="grow flex flex-col space-y-1 my-1">
            <InfoSmall>
              {p.product.qteDispoVente} disponible (
              {p.product.codeEtat === "S"
                ? `Supprimé: ${p.product.fournHabit}`
                : `${p.product.dispoHabit} - ${p.product.fournHabit}`}
              )
            </InfoSmall>
            <InfoSmall>
              {p.product.qteDispoVente} {currentShop?.devisSymb || "€"} TTC PU
            </InfoSmall>
            <InfoSmall>Rayon: {p.product.libRayon}</InfoSmall>
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      orderable: true,
      render: (p) => (
        <div className="w-full flex gap-2">
          <ProductActions product={p.product} />
        </div>
      ),
    },
  ];

  return (
    <Page>
      <Title className="text-center mx-8 mt-8">
        {t("products.basket.total")}:{" "}
        {formatAmount(
          getPrice(),
          currentShop?.devisSymb,
          currentShop?.devisNbDecim
        )}
      </Title>
      {basketCustomer.numCli && (
        <Subtitle className="text-center mt-2">
          {basketCustomer.nomCli} {basketCustomer.prnomCli} -{" "}
          <a
            className="hover:cursor-pointer hover:text-slate-400"
            href={`mailto:${basketCustomer.addrMail}`}
          >
            {basketCustomer.addrMail}{" "}
          </a>
          {" - "}
          <a
            className="hover:cursor-pointer hover:text-slate-400"
            href={`tel:${basketCustomer.mobil}`}
          >
            {basketCustomer.mobil}{" "}
          </a>
        </Subtitle>
      )}

      <Modal open={share} onClose={() => setShare(false)}>
        <ModalContent title="Partager ce panier">
          <div className="w-full flex items-center justify-center p-4">
            <QRCodeCanvas
              value={JSON.stringify({
                type: "basket",
                content: getBasket(basketId || "")?.products.map((a) => [
                  a.product.id,
                  a.count,
                ]),
              })}
            />
          </div>
          <Info>
            Scanner ce QR code pour ouvrir ce panier sur un autre appareil.
          </Info>
        </ModalContent>
      </Modal>
      {!basket.length && (
        <div className="flex flex-col grow justify-center items-center space-y-4 m-8 ">
          <Info>Ce panier est vide.</Info>
        </div>
      )}

      {!!basket.length && (
        <div className="flex grow justify-center items-center space-x-4 m-8 mb-4">
          <ButtonConfirm
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              removeBasket(basketId || "");
            }}
            theme="danger"
            confirmTitle="Supprimer le panier"
            confirmMessage="Vous êtes sur le point de surtout le panier actif ainsi que sont contenu"
            confirmButtonText="Continuer"
            cancelButtonText="Annuler"
            data-tooltip="Supprimer ce panier"
            icon={({ className }) => <TrashIcon className={className} />}
            shortcut={[ShortcutMapping.products.remove_basket]}
          />
          <Button
            size="lg"
            disabled={Env.isBeta}
            onClick={(e) => {
              toast("Fonctionnalité bientôt disponible");
              // e.stopPropagation();
              // navigate(ROUTES.SupplierRetourMulti, {
              //   state: { from: location.pathname },
              // });
            }}
            theme="secondary"
            data-tooltip="Fonctionnalité bientôt disponible"
            icon={({ className }) => <ReplyIcon className={className} />}
            shortcut={[ShortcutMapping.products.start_supplier_return]}
          />
          <Button
            size="lg"
            disabled={Env.isBeta}
            onClick={(e) => {
              toast("Fonctionnalité bientôt disponible");
              // e.stopPropagation();
              // navigate(ROUTES.SupplierCommandMulti, {
              //   state: { from: location.pathname },
              // });
            }}
            theme="secondary"
            data-tooltip="Fonctionnalité bientôt disponible"
            icon={({ className }) => <TruckIcon className={className} />}
            shortcut={[ShortcutMapping.products.start_supplier_order]}
          />
          <Button
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              navigate(ROUTES.BasketClientOrder.replace(":id", basketId || ""));
            }}
            theme="secondary"
            data-tooltip="Passer commande client"
            icon={({ className }) => <ShoppingBagIcon className={className} />}
            shortcut={[ShortcutMapping.products.start_client_order]}
          />
          <Button
            size="lg"
            onClick={() =>
              openOptions({
                options: [
                  {
                    label: "Partager ce panier",
                    onClick: () => {
                      setShare(true);
                    },
                  },
                ],
              })
            }
            theme={"secondary"}
            data-tooltip="Plus d'options"
            icon={({ className }) => (
              <DotsHorizontalIcon className={className} />
            )}
          />
        </div>
      )}

      <div className="mb-8 mx-auto w-full flex" style={{ maxWidth: 240 }}>
        <InputWithSuggestions
          inputMode={scanOn ? "none" : undefined}
          value={scanValue}
          options={[]}
          ean={true}
          inputClassName="to-focus !rounded-l-lg !rounded-r-none"
          autoFocus={true}
          placeholder="Scanner"
          enableMic={false}
          onChange={(e) => setScanValue(e.target.value)}
        />

        <Button
          className="shrink-0 !rounded-r-lg !rounded-l-none"
          shortcut={["enter"]}
          icon={(p) => <PlusIcon {...p} />}
          onClick={async () => await handleSubmit(scanValue)}
        />
      </div>

      {!!basket.length && (
        <TableGridSwitch
          name="Panier"
          onFetchExportData={async () =>
            currentBasketProducts.map((e) => ({ ...e.product, count: e.count }))
          }
          exportDefaultHeader={[
            "designation",
            "id",
            "qteDispoVente",
            "qteDispoVenteFutur",
            "dispoHabit",
            "fournHabit",
            "libRayon",
          ]}
          data={currentBasketProducts}
          onClick={(product) => {
            navigate(
              ROUTES.Product.replace(/:type/, product.product.modeGest)
                .replace(/:ean/, product.product.id)
                .replace(/:referGestion/, "true")
            );
          }}
          gridMode={gridMode.activated}
          onChangeMode={(v) => setGridMode({ activated: v })}
          renderGrid={(product) => (
            <ProductCard product={product.product as ProductTypeShort} />
          )}
          tableColumns={columns}
          total={
            productsBaskets.find((e) => e.id === basketId)?.products?.length ||
            0
          }
          showPagination={false}
        />
      )}
    </Page>
  );
};
