import { Button } from "@atoms/button/button";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { EclatKitModalAtom } from "../eclat-kit-modal";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionLine } from "@features/reception/types";

type UnknownArticlesModalType = {
  open: boolean;
  unknownArticles: { ean13: string; designation: string }[];
  kits: ReceptionLine[];
};

export const UnknownArticlesModalAtom = atom<UnknownArticlesModalType>({
  key: "UnknownArticlesModal",
  default: { open: false, unknownArticles: [], kits: [] },
});

export const UnknownArticlesModal = () => {
  const [unknownArticlesModal, setUnknownArticlesModal] = useRecoilState(
    UnknownArticlesModalAtom
  );

  const { selectedReception } = useReception();
  const setEclatKitModal = useSetRecoilState(EclatKitModalAtom);

  const numCde: Column<{ ean13: string; designation: string }> = {
    title: "EAN 13",
    render: (el) => el.ean13,
  };
  const select: Column<{ ean13: string; designation: string }> = {
    render: (el) => el.designation,
  };

  const handleClose = () => {
    setUnknownArticlesModal({ open: false, unknownArticles: [], kits: [] });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={unknownArticlesModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Produits inconnus"}>
        <div className="w-full flex flex-col gap-4">
          <Table
            name="unknownArticlePage"
            emptyTabText={"Aucune commande associée"}
            columns={[numCde, select]}
            data={unknownArticlesModal.unknownArticles}
          />
        </div>
        <Button
          onClick={() => {
            handleClose();
            if (unknownArticlesModal.kits.length > 0) {
              setTimeout(() => {
                setEclatKitModal({
                  open: true,
                  receipt: selectedReception,
                  products: unknownArticlesModal.kits,
                });
              }, 1000);
            }
          }}
          // navigate(
          //   ROUTES.OngoingReception.replace(
          //     /:numRecep/,
          //     selectedReception.numRecep
          //   )
          // );
        >
          Continuer
        </Button>
      </ModalContent>
    </Modal>
  );
};
