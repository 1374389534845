import { Button } from "@atoms/button/button";
import { InputFormat } from "@atoms/input/input-format";
import Select from "@atoms/input/input-select";
import { Input } from "@atoms/input/input-text";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, InfoSmall, InfoSmallBold } from "@atoms/text";
import { ProductType } from "@features/products/types";
import { atom, useRecoilState } from "recoil";

type RegulStockModalType = {
  open: boolean;
  product?: ProductType;
};

export const RegulStockModalAtom = atom<RegulStockModalType>({
  key: "RegulStockModal",
  default: {
    open: false,
  },
});

export const RegulStockModal = () => {
  const [regulStockModal, setRegulStockModal] =
    useRecoilState(RegulStockModalAtom);

  const handleClose = () => {
    setRegulStockModal({ open: false, product: undefined });
  };

  return (
    <Modal
      className="sm:max-w-[500px]"
      open={regulStockModal.open}
      onClose={() => handleClose()}
    >
      {regulStockModal.product && (
        <ModalContent title="Régularisation de stock">
          <div className="flex gap-2 mb-4">
            <InfoSmallBold className="flex flex-col justify-center h-full ">
              {regulStockModal.product.designation}
            </InfoSmallBold>
            <InfoSmallBold> - </InfoSmallBold>
            <InfoSmall className="flex flex-col justify-center h-full">
              {regulStockModal.product.id}
            </InfoSmall>
          </div>
          <Frame className="grow flex flex-col gap-4">
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">Motif</Base>
              <div className="w-full flex justify-start ">
                <Select className="max-w-64" />
              </div>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">Libellé</Base>
              <div className="w-full flex justify-start ">
                <Input className="max-w-64" />
              </div>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">Stock avant: 10€</Base>
              <div className="w-full flex justify-start ">
                <InputFormat className="max-w-20" format={"price"} />
              </div>
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Base className="w-full">PAMP avant: 10€</Base>
              <Base className="w-full text-start">PAMP après: 10€</Base>
            </div>
          </Frame>
          <div className="w-full flex gap-4 justify-center mt-4">
            <Button theme="danger">Quitter</Button>
            <Button theme="valid">Valider</Button>
          </div>
        </ModalContent>
      )}
    </Modal>
  );
};
