import Accordion from "@atoms/accordion";
import { BookRender } from "@atoms/book";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Page } from "@atoms/layout/page";
import { PageLoader } from "@atoms/layout/page-loader";
import { Base, BaseBold, BaseSmall, SectionSmall, Title } from "@atoms/text";
import { getProductTypeIcon, ProductActions } from "@components/product";
import { useProduct } from "@features/products/state/use-product";
import { useProductEditor } from "@features/products/state/use-product-editor";
import { Book, ProductType } from "@features/products/types";
import { formatAmount } from "@features/utils/format/strings";
import { NotAvailablePage } from "@views/client/not-available";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import {
  formDisplayer,
  isPanelEmpty,
} from "@features/product-forms/form-displayer";
import { Form } from "@molecules/form";
import { Frame } from "@atoms/layout/frame";
import { SuppliersModal } from "./sublists/suppliers";
import { PricesModal } from "./sublists/prices";
import { CatalogueModal } from "./sublists/catalogues";
import { EcoPartModal } from "./sublists/eco-participation";
import { PricesDegModal } from "./sublists/prices-degressif";
import { ConditionAchatModal } from "./sublists/conditions-achat";
import { ProdEanModal } from "./sublists/prod-ean";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { StockTab } from "@components/product/stockTab";
import { InfosArea } from "@molecules/form/infos";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { useBaskets } from "@features/products/state/use-baskets";
import { useBasket } from "@features/products/state/use-basket";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { AcademicCapIcon } from "@heroicons/react/outline";
import { ProductGraph } from "@components/product/product-graph";

export const ProductPage = () => {
  const { ean } = useParams<{ ean: string }>();

  const [tab, setTab] = usePersistedState<any>("user", "tabProductSave", {
    dispo: true,
  });

  const [editing, setEditing] = useState(false);
  const {
    refresh,
    product: originalProduct,
    type,
    loading,
  } = useProduct(ean || "");
  const { current: currentShop } = useShopLocations();
  const { current } = useBaskets();
  const { products } = useBasket(current);
  const fiche = formDisplayer(originalProduct, type || "L");
  const [plcvInfos, setPlcvInfos] = useState({
    index: 0,
    choixCanal: "",
  });

  useControlledEffect(() => {
    if (
      products.find((el) => el.product.id === ean) &&
      originalProduct &&
      originalProduct.listPlcv[0].codeCanalVente === ""
    ) {
      const timeoutId = setTimeout(refresh, 250);

      return () => clearTimeout(timeoutId);
    }
  }, [products]);

  useControlledEffect(() => {
    if (originalProduct) {
      //Pour le nom de l'onglet
      document.title = "Inférence - " + originalProduct.designation;
      //Pour PLCV
      const princIndex = originalProduct.listPlcv.findIndex(
        (el) => el.princ === true
      );
      if (princIndex === -1) {
        setPlcvInfos({
          index: 0,
          choixCanal: "Canal par défault",
        });
      } else {
        setPlcvInfos({
          index: princIndex,
          choixCanal: originalProduct.listPlcv[princIndex].libCanalVente,
        });
      }
    }
  }, [originalProduct]);

  const {
    draft,
    setDraft,
    save,
    cancel,
    loading: loadingChange,
  } = useProductEditor(ean || "");

  if (loading) {
    return <PageLoader />;
  }

  if (!originalProduct) {
    return <NotAvailablePage />;
  }

  const product = { ...originalProduct, ...draft };

  if (originalProduct) {
    return (
      <Page>
        <SuppliersModal />
        <PricesModal />
        <CatalogueModal />
        <EcoPartModal />
        <PricesDegModal />
        <ConditionAchatModal />
        <ProdEanModal />

        <Title>{product?.designation}</Title>

        <div className="flex flex-col sm:flex-row items-center relative">
          <div className="w-full flex flex-col sm:flex-row space-x-2 md:space-x-4 mx-auto my-3 justify-center">
            <div className="border border-slate-200 w-7 h-7 sm:w-11 sm:h-11 bg-white dark:bg-slate-900 flex items-center justify-center self-center">
              {getProductTypeIcon(product.codeTypeProd)}
            </div>
            <div className="flex gap-2 mt-4 sm:mt-0">
              <ProductActions
                shortcuts
                size={window.innerWidth > 400 ? "lg" : "sm"}
                product={product}
              />
            </div>
          </div>

          <div className="right-0 sm:absolute">
            {/* {!editing && !loadingChange && (
              <Button
                disabled={Env.isBeta}
                theme="outlined"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Modifier
              </Button>
            )} */}

            {(!!editing || loadingChange) && (
              <div className="flex space-x-2">
                <ButtonConfirm
                  theme="default"
                  confirmTitle="Annuler les modifications"
                  confirmMessage="Vous êtes sur le point de supprimer vos changements."
                  disabled={loadingChange}
                  onClick={() => {
                    setEditing(false);
                    cancel();
                  }}
                >
                  Annuler
                </ButtonConfirm>
                <ButtonConfirm
                  theme="primary"
                  confirmTitle="Confirmer les modifications"
                  confirmMessage="Vous êtes sur le point de sauvegarder vos changements."
                  loading={loadingChange}
                  onClick={async () => {
                    setEditing(false);
                    await save();
                  }}
                >
                  Sauvegarder
                </ButtonConfirm>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center space-y-8 lg:space-y-0 lg:flex-row lg:items-start lg:space-x-8 mt-6 ">
          <div className="flex flex-col sm:flex-row gap-8 items-center sm:items-start">
            <div className="shrink-0 w-80 max-w-xs ">
              <div className="flex justify-center h-52 md:h-full max-w-xs mx-auto ">
                <BookRender
                  src={product?.imageURL}
                  alt={product?.designation}
                  productType={product.modeGest}
                  textInfoPos="under-img"
                />
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <Frame>
                <div className="h-full flex flex-col shrink-0 w-80 gap-2">
                  <SectionSmall>Informations générales</SectionSmall>
                  <Form
                    readonly={!editing}
                    value={product}
                    original={originalProduct}
                    onChange={(t) => setDraft(t as ProductType)}
                    fields={fiche.general}
                  />
                  {/*Pas sûr de ce qui est voulu, donc pas propre pour le moment*/}
                  <BaseBold className="!text-blue-600">
                    {`  ${formatAmount(
                      product?.prixVente || 0,
                      currentShop?.devisSymb || "€",
                      currentShop?.devisNbDecim || 2
                    )}`}
                    <BaseSmall className="text-center font-semibold">
                      TTC
                    </BaseSmall>
                  </BaseBold>
                  {!product.referGestion ||
                  product.qteDispoVente === product.qteDispoVenteFutur ? (
                    <>
                      <Base>
                        {product.qteDispoVente}{" "}
                        <label className=" text-sm font-medium opacity-50 text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                          {" "}
                          disponible à la vente
                        </label>{" "}
                        <label className=" text-sm font-medium opacity-50 text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                          {product.dispoHabit
                            ? `(${product.dispoHabit} - ${product.fournHabit})`
                            : ""}
                        </label>
                      </Base>
                    </>
                  ) : (
                    <>
                      <Base>
                        `${product.qteDispoVente}{" "}
                        <label className=" text-sm font-medium opacity-50 text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                          disponibles
                        </label>{" "}
                        {product.qteDispoVenteFutur}{" "}
                        <label className=" text-sm font-medium opacity-50 text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                          future
                        </label>{" "}
                        <label className=" text-sm font-medium opacity-50 text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                          {product.dispoHabit
                            ? `(${product.dispoHabit} - ${product.fournHabit})`
                            : ""}
                        </label>
                      </Base>
                    </>
                  )}
                  {/* <Info>{`${product.dispoHabit} - ${product.fournHabit}`}</Info> */}
                  <div className="flex flex-row">
                    {(product as Book).indicScol && (
                      <AcademicCapIcon
                        className="h-6 w-6 text-stone-800"
                        data-tooltip="Livre scolaire"
                      />
                    )}
                  </div>
                </div>
              </Frame>
            </div>
          </div>

          <div className="flex flex-col px-8 w-full h-full md:p-0">
            <Accordion
              onChange={(newTabValue) => setTab(newTabValue)}
              value={tab}
              panels={[
                {
                  value: "identification",
                  label: t("products.livre.identification.identification"),
                  node: (
                    <Form
                      onChange={(t) => setDraft(t as ProductType)}
                      readonly={!editing}
                      value={product}
                      original={originalProduct}
                      fields={fiche.identification}
                    />
                  ),
                },
                {
                  value: "classification",
                  invisible: isPanelEmpty(product),
                  label: t("products.livre.classification.classification"),
                  node: (
                    <Form
                      onChange={(t) => setDraft(t as ProductType)}
                      readonly={!editing}
                      value={product}
                      original={originalProduct}
                      fields={fiche.classification}
                    />
                  ),
                },
                {
                  value: "vente",
                  label: t("products.livre.vente.vente"),
                  node: (
                    <>
                      <Form
                        readonly={!editing}
                        onChange={(t) => setDraft(t as ProductType)}
                        value={product}
                        original={originalProduct}
                        fields={fiche.venteGeneral}
                      />
                      {product.listPlcv[0].codeCanalVente !== "" && (
                        <>
                          {" "}
                          <hr className="my-1 -mx-4" />
                          <Form
                            onChange={(t) =>
                              setPlcvInfos({
                                index: product.listPlcv.findIndex(
                                  (plcv) => plcv.libCanalVente === t.choixCanal
                                ),
                                choixCanal: t.choixCanal,
                              })
                            }
                            value={plcvInfos}
                            fields={[
                              {
                                key: "choixCanal",
                                label: t("products.livre.vente.choixCanal"),
                                type: "select",
                                autoFocus: true,
                                options: async () => {
                                  return product.listPlcv.map((plcv) => {
                                    return {
                                      label: plcv.libCanalVente,
                                      value: plcv.libCanalVente,
                                    };
                                  });
                                },
                              },
                            ]}
                          />
                          <hr className="my-1 -mx-4" />
                          <Form
                            onChange={(t) =>
                              setDraft({
                                ...draft,
                                plcv: product?.listPlcv.map((plcv, index) => {
                                  return index === plcvInfos.index ? t : plcv;
                                }),
                              } as ProductType)
                            }
                            readonly={!editing}
                            value={product.listPlcv[plcvInfos.index]}
                            fields={[
                              //JE MET DE CÔTE POUR LE MOMENT --> à voir la pertinence de ce champ
                              /*  {
                            key: "info",
                            type: "custom",
                            node: () => (
                              <div className="">
                                <Info>
                                  Informations pour le canal de vente{" "}
                                  <BaseSmall>
                                    {" "}
                                    {
                                      product.listPlcv[plcvInfos.index]
                                        .libCanalVente
                                    }
                                  </BaseSmall>
                                  .{" "}
                                </Info>
                              </div>
                            ),
                          }, */
                              ...fiche.venteCanal,
                            ]}
                          />
                        </>
                      )}
                    </>
                  ),
                },

                fiche.approvisionnement && {
                  value: "approvisionnement",
                  label: t(
                    "products.livre.approvisionnement.approvisionnement"
                  ),
                  node: (
                    <>
                      <InfosArea
                        fields={[
                          {
                            mainText: `${product["qteDispoVenteFuturFutur"]} disponibles à la vente future après validation des saisies en cours `,
                            secondText: `${product["qteDispoVenteFutur"]} disponibles à la vente future, ${product["qtePrepCdePlusTrans"]} en préparation de commande et transfert (dont ${product["qteReserCliPrep"]} réservés clients)`,
                          },
                        ]}
                      />
                      <Form
                        readonly={!editing}
                        value={product}
                        original={originalProduct}
                        onChange={(t) => setDraft(t as ProductType)}
                        fields={fiche.approvisionnement}
                      />{" "}
                    </>
                  ),
                },
                {
                  value: "stock",
                  label: t("products.livre.stock.stock"),
                  node: (
                    <Form
                      readonly={!editing}
                      value={product}
                      original={originalProduct}
                      onChange={(t) => setDraft(t as ProductType)}
                      fields={fiche.stock}
                    />
                  ),
                },
                {
                  value: "dispo",
                  label: "Disponibilité",
                  node: (
                    <div className="flex flex-col gap-4">
                      <StockTab product={product} />
                      <Frame>
                        <ProductGraph ean={product.id} product={product} />
                      </Frame>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Page>
    );
  }
};
