import Link from "@atoms/link";
import { Info } from "@atoms/text";
import { CustomerMode } from "@features/customer/state/store";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ModuleType, modules } from "@features/modules";
import { ROUTES } from "@features/routes";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Account } from "./account";
import { useEffect, useState } from "react";

export const HeaderWidgetsDomId = "header-widget";

export const Header = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const location = useLocation();
  const customerMode = useRecoilValue(CustomerMode);
  const [prevModule, setPrevModule] = useState<ModuleType | undefined>();
  const { current: currentShopLocation } = useShopLocations();
  let currentModule = modules.find(
    (m) =>
      location.pathname !== ROUTES.Home &&
      location.pathname.indexOf(m.route) === 0
  );

  //Temp fix
  if (!currentModule) {
    if (location.pathname.includes("commandSimple")) {
      currentModule = modules.find((m) => m.route.includes("commandSimple"));
    }
    if (location.pathname.includes("retourSimple")) {
      currentModule = modules.find((m) => m.route.includes("retourSimple"));
    }
  }

  useEffect(() => {
    if (location.state && "from" in location.state) {
      const fromMod = modules.find((el) => el.route === location.state.from);
      setPrevModule(fromMod);
    }
  }, [location]);

  return (
    <div
      className={`${
        customerMode && /^\/products/.test(location.pathname)
          ? "bg-amber-100"
          : "bg-white"
      } dark:bg-slate-950 dark:text-white dark:border-slate-600 border-b h-11 flex flex-row justify-center items-center px-2 sm:px-4 min-h-0 shrink-0 z-60 print:hidden`}
    >
      <div className="shrink-0">
        <Logo />
      </div>
      <div className="flex grow whitespace-nowrap overflow-hidden text-ellipsis">
        {!!user && (
          <Info noColor>
            <Link className="hover:text-blue-600" noColor to={ROUTES.Home}>
              {t("header.home")}
            </Link>
            {!!currentModule?.useFrom && prevModule && (
              <>
                <HeaderSeparatorChevron />
                <Link
                  className="hover:text-blue-600"
                  noColor
                  to={prevModule.route}
                >
                  {prevModule.name}
                </Link>
              </>
            )}
            {!!currentModule?.name && (
              <>
                <HeaderSeparatorChevron />
                <Link
                  className="hover:text-blue-600"
                  noColor
                  to={currentModule?.route}
                >
                  {currentModule?.name}
                </Link>
              </>
            )}
          </Info>
        )}
        <div className="grow mr-2" />
        {!!user && (
          <Info noColor>
            {!!currentShopLocation?.libLieu &&
              location.pathname === ROUTES.Home && (
                <>
                  <Link
                    className="hover:text-blue-600"
                    to={ROUTES.Locations}
                    noColor
                    //onClick={() => saveLocation(null)}
                  >
                    {currentShopLocation?.libLieu}
                  </Link>
                  <HeaderSeparator />
                </>
              )}
          </Info>
        )}
      </div>

      <div
        className="shrink-0 flex flex-row items-center"
        id={HeaderWidgetsDomId}
      />
      {!!user && (
        <>
          <div className="flex flex-row items-center justify-center md:ml-0">
            <Account />
          </div>
        </>
      )}
    </div>
  );
};

const Logo = () => (
  <Link to={"/"} noColor className="flex-row items-center mr-4 flex">
    <img src="/medias/logo-square.png" className="h-6 w-6" alt="Inférence" />
  </Link>
);

export const HeaderSeparatorChevron = () => (
  <ChevronRightIcon className="h-3 w-3 opacity-50 inline-block mx-1 relative -top-px" />
);

export const HeaderSeparator = () => (
  <div className="h-4 opacity-50 inline-block mx-3 relative top-1 border-l border-solid border-slate-500 rounded-lg" />
);
